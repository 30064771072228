import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Box } from '../../services/models/assets';
import { Link } from 'react-router-dom';
import RentalService from '../../services/RentalService';
import { useQuery } from '@tanstack/react-query';

interface ModalChooseBoxProps {
  selectedEquipmentId?: number;
  visible: boolean;
  rentalId?: number;
  onClose: () => void;
  onBoxSelect: (box: Box) => void;
}

const ModalChooseBox: React.FC<ModalChooseBoxProps> = (props: ModalChooseBoxProps) => {
  const { data: boxes, isFetching } = useQuery({
    queryKey: ['boxes'],
    queryFn: () => RentalService.getAvailableBoxes(props.rentalId!),
    select: (data) => data.boxes,
    enabled: props.visible && props.rentalId != null,
  });

  const handleBoxSelect = (box: Box) => {
    props.onBoxSelect(box);
    props.onClose();
  };

  return (
    <Transition.Root show={props.visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={props.onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Choisis un autre box
              </Dialog.Title>
              <div className="mt-4">
                <ul className="grid grid-cols-1 gap-2">
                  {boxes?.map((box) => (
                    <li
                      key={box.id}
                      className="flex-1"
                    >
                      <button
                        className="w-full px-4 py-2 rounded-md flex gap-2 items-center justify-between bg-gray-100 disabled:opacity-50"
                        onClick={() => handleBoxSelect(box)}
                      >
                        <p className="text-gray-900">{`Box #${box.boxNumber}`}</p>
                        {box.equipments?.map((equipment) => (
                          <div className="flex gap-2 items-center">
                            <img src={equipment.imageUrl} alt={equipment.model} className="w-10 h-10 rounded-lg" />
                            <p className="text-gray-900">{equipment.model}</p>
                          </div>
                        ))}
                      </button>
                    </li>
                  ))}
                  {boxes && boxes.length === 0 && !isFetching && (
                    <li>
                      <p className="text-gray-900">Pas d'équipements disponibles.</p>
                      <Link to="/equipments" className="text-blue-500">Créer un équipement</Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalChooseBox;
