import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Rental, RentalStatus } from '../../services/models/rental';
import AssetsService from '../../services/AssetsService';
import toast from 'react-hot-toast';
import moment from 'moment';
import RentalService from '../../services/RentalService';
import ModalChooseBox from './ModalChooseBox';
import { Box, Equipment } from '../../services/models/assets';

interface ModalRentalDetailsProps {
  rental?: Rental;
  visible: boolean;
  onClose: () => void;
  onUpdate: (rental: Rental) => void;
  onEndRental: () => void;
}

const ModalRentalDetails: React.FC<ModalRentalDetailsProps> = ({ rental, visible, onClose, onUpdate, onEndRental }) => {
  const [savedRental, setSavedRental] = useState<Rental>();
  const [isEditing, setIsEditing] = useState(false);
  const [editStartDate, setEditStartDate] = useState<string>();
  const [changingEquipmentId, setChangingEquipmentId] = useState<number>();

  const savedRentalFinished = useMemo(() => (
    savedRental && new Date(savedRental.endDateUTC) < new Date()
  ), [savedRental]);


  const duration = useMemo(() => {
    const startDate = moment.utc(rental?.startDateUTC);
    const endDate = moment.utc(rental?.endDateUTC);

    if (endDate.isBefore(startDate)) {
      return 'Annulé';
    }
    if (endDate.diff(startDate, 'days') === 0) {
      return `${endDate.diff(startDate, 'hours')} heures`;
    }
    return `${endDate.diff(startDate, 'days')} jours`;
  }, [rental]);

  useEffect(() => {
    if (visible) {
      setSavedRental(rental);
    }
  }, [visible, rental]);

  const openBox = (boxId: number) => {
    AssetsService.openBox(boxId)
      .then(() => {
        toast.success('Action envoyé');
      })
      .catch(console.warn);
  };

  const endRental = () => {
    if (rental) {
      RentalService.endRental(rental.id)
        .then((response) => {
          if (response.success) {
            toast.success('Réservation finalisée');
            onEndRental();
          } else {
            toast.error('Erreur lors de la finalisation de la réservation');
          }
        })
        .catch(console.warn);
    }
  };

  const closeRental = () => {
    if (rental) {
      RentalService.closeRental(rental.id)
        .then((response) => {
          if (response.success) {
            toast.success('Réservation finalisée');
            onEndRental();
          } else {
            toast.error('Erreur lors de la finalisation de la réservation');
          }
        })
        .catch(console.warn);
    }
  };

  const saveChanges = () => {
    if (rental) {
      RentalService.updateRentalStartDateUTC(rental.id, editStartDate!)
        .then((response) => {
          if (response) {
            toast.success('Réservation modifiée');
            setIsEditing(false);
            setSavedRental({
              ...savedRental,
              ...response,
            });
            onUpdate(response);
          } else {
            toast.error('Erreur lors de la modification de la réservation');
          }
        })
        .catch(console.warn);
    }
  };

  const showModalChooseBox = (equipmentId: number) => {
    if (rental != null) {
      setChangingEquipmentId(equipmentId);
    }
  };

  const changeEquipment = (equipment: Equipment) => {
    if (rental != null) {
      RentalService.changeRentalEquipment(rental.id, changingEquipmentId!, equipment.id)
        .then((response) => {
          if (response) {
            toast.success('Box modifiée');
            setSavedRental({
              ...savedRental,
              ...response,
            });
            onUpdate(response);
          } else {
            toast.error('Erreur lors de la modification de la réservation');
          }
        })
        .catch(console.warn)
        .finally(() => setChangingEquipmentId(undefined));
    }
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 mx-5 sm:mx-0 w-full max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-left w-full">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 mb-5">
                      {`Réservation #${savedRental?.id}`}
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      {savedRental?.equipments?.map((equipment) => (
                        <div key={equipment.id} className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <img src={equipment.imageUrl} alt={equipment.model} className="h-12 w-12 rounded-lg" />
                            <p className="text-gray-900 ml-3">{equipment.model}</p>
                          </div>
                          <div className="flex items-center gap-2">
                            {!savedRentalFinished && (
                              <button
                                type="button"
                                className="py-2 px-4 flex items-center justify-center border bg-orange-400 rounded-md shadow-sm font-semibold"
                                onClick={() => openBox(equipment.boxId)}
                              >
                                <span className="text-white">Ouvrir</span>
                              </button>
                            )}
                            {savedRental.rentalStatus === RentalStatus.NOT_STARTED && (
                              <button
                                type="button"
                                className="py-2 px-4 flex items-center justify-center border bg-blue-400 rounded-md shadow-sm font-semibold"
                                onClick={() => showModalChooseBox(equipment.id)}
                              >
                                <span className="text-white">Changer</span>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                      {savedRental && (
                        <>
                          <div className="border-t border-t-gray-200 w-full mt-5 py-5">
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Début</p>
                              {isEditing ? (
                                <input
                                  type="datetime-local"
                                  value={moment.utc(editStartDate).local().format('YYYY-MM-DDTHH:mm')}
                                  onChange={(e) => setEditStartDate(moment(e.target.value).utc().format('YYYY-MM-DDTHH:mm'))}
                                  className="text-gray-900"
                                  min={moment().subtract(1, 'minute').format('YYYY-MM-DDTHH:mm')}
                                />
                              ) : (
                                <p className="text-gray-900">{moment.utc(savedRental.startDateUTC).local().format('DD/MM/YYYY HH:mm')}</p>
                              )}
                            </div>
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Durée</p>
                              <p className={`${duration === 'Annulé' ? 'text-red-500' : 'text-gray-900'}`}>{duration}</p>
                            </div>
                            {rental?.rentalStatus === RentalStatus.NOT_STARTED && (
                              isEditing ? (
                                <div className="flex justify-end mt-3">
                                  <button
                                    type="button"
                                    className="text-blue-500"
                                    onClick={saveChanges}
                                  >
                                    Sauvegarder et envoyer un mail au client
                                  </button>
                                  <button
                                    type="button"
                                    className="text-red-500 ml-4 flex-1 text-right"
                                    onClick={() => setIsEditing(false)}
                                  >
                                    Annuler
                                  </button>
                                </div>
                              ) : (
                                <div className="flex justify-end mt-3">
                                  <button
                                    type="button"
                                    className="text-gray-500"
                                    onClick={() => {
                                      setEditStartDate(savedRental?.startDateUTC);
                                      setIsEditing(true);
                                    }}
                                  >
                                    Modifier
                                  </button>
                                </div>
                              )
                            )}
                          </div>
                          <div className="border-t border-t-gray-200 w-full mt-5 py-5">
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Prix</p>
                              <p className="text-gray-900">{`${(savedRental.price / 100).toFixed(2)} €`}</p>
                            </div>
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Caution</p>
                              <p className="text-gray-900">{`${(savedRental.deposit / 100).toFixed(2)} €`}</p>
                            </div>
                          </div>
                          <div className="border-t border-t-gray-200 w-full mt-5 py-5">
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Nom du loueur</p>
                              <p className="text-gray-900">{`${savedRental.user.firstName} ${savedRental.user.lastName}`}</p>
                            </div>
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Email du loueur</p>
                              <p className="text-gray-900">{savedRental.user.email}</p>
                            </div>
                            <div className="flex justify-between">
                              <p className="text-gray-900 font-semibold">Téléphone du loueur</p>
                              <p className="text-gray-900">{savedRental.user.phone}</p>
                            </div>
                            {(savedRental.user.sosFirstname || savedRental.user.sosLastname || savedRental.user.sosPhone) && (
                              <div className="border-t border-t-gray-200 w-full mt-5 py-5">
                                {(savedRental.user.sosFirstname || savedRental.user.sosLastname) && (
                                  <div className="flex justify-between">
                                    <p className="text-gray-900 font-semibold">Nom du contact d'urgence</p>
                                    <p className="text-gray-900">{`${savedRental.user.sosFirstname ? `${savedRental.user.sosFirstname} ` : ''}${savedRental.user.sosLastname ?? ''}`}</p>
                                  </div>
                                )}
                                <div className="flex justify-between">
                                  <p className="text-gray-900 font-semibold">Téléphone du contact d'urgence</p>
                                  <p className="text-gray-900">{savedRental.user.sosPhone}</p>
                                </div>
                              </div>
                            )}
                            {(savedRental.comment && (
                              <div className="border-t border-t-gray-200 w-full mt-5 py-5">
                                <div className="flex justify-between">
                                  <p className="text-gray-900 font-semibold">Commentaire</p>
                                  <p className="text-gray-900">{savedRental.comment}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 flex justify-between">
                  {!savedRentalFinished ? (
                    savedRental?.rentalStatus === RentalStatus.NOT_STARTED ? (
                      <button
                        type="button"
                        className="mt-3 -ml-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-red-500 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={endRental}
                      >
                        Annuler réservation
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="py-2 px-4 flex items-center justify-center border bg-red-400 rounded-md shadow-sm font-semibold"
                        onClick={endRental}
                      >
                        <span className="text-white">Finaliser</span>
                      </button>
                    )
                  ) : (savedRental?.rentalStatus !== RentalStatus.CLOSED ? (
                      <button
                        type="button"
                        className="py-2 px-4 flex items-center justify-center border bg-green-400 rounded-md shadow-sm font-semibold"
                        onClick={closeRental}
                      >
                        <span className="text-white">Rendre disponible</span>
                      </button>
                  ) : (
                    <div />
                  ))}
                  <button
                    type="button"
                    className="py-2 px-4 flex items-center justify-center border rounded-md shadow-sm font-semibold text-gray-600"
                    onClick={onClose}
                  >
                    Fermer
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <ModalChooseBox
            visible={changingEquipmentId != null}
            onClose={() => setChangingEquipmentId(undefined)}
            rentalId={savedRental?.id}
            onBoxSelect={(box) => changeEquipment(box.equipments[0])}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalRentalDetails;
