import { Equipment, Location } from './assets';

export enum RentalStatus {
  NOT_STARTED = 1,
  RENTED = 2,
  RETURNED = 3,
  CLOSED = 4,
}

export interface RentalUser {
  sosFirstname?: string;
  sosLastname?: string;
  sosPhone?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export enum RentalDurationUnit {
  DAY = 'day',
  HOUR = 'hour',
}

export interface RentalDuration {
  id: number;
  unit: RentalDurationUnit;
  duration: number;
}

export interface Rental {
  id: number;
  startDateUTC: string;
  endDateUTC: string ;
  comment?: string;
  token?: string;
  rentalStatus: RentalStatus;
  price: number;
  deposit: number;
  locationId: number;

  location?: Pick<Location, 'name'>;
  equipments?: Equipment[];
  user: RentalUser;
}
