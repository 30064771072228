import { CalendarIcon, HomeIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardEquipments from './pages/Dashboard/pages/DashboardEquipments/DashboardEquipments';
import DashboardLocationDetails from './pages/Dashboard/pages/DashboardLocationDetails/DashboardLocationDetails';
import DashboardLocationNew from './pages/Dashboard/pages/DashboardLocationNew/DashboardLocationNew';
import DashboardRentals from './pages/Dashboard/pages/DashboardRentals/DashboardRentals';
import DashboardStationNew from './pages/Dashboard/pages/DashboardStationNew/DashboardStationNew';
import DashboardStations from './pages/Dashboard/pages/DashboardStations';
import { Menu } from './pages/Dashboard/types/common';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Signup from './pages/Signup';

const App = () => {
  const menus = useMemo<Menu[]>(() => ([{
    title: 'Mes stations',
    icon: <HomeIcon width={24} />,
    path: '/stations',
    element: <DashboardStations />,
  }, {
    title: 'Mes équipements',
    icon: <ShoppingBagIcon width={24} />,
    path: '/equipments',
    element: <DashboardEquipments />,
  }, {
    title: 'Réservations',
    icon: <CalendarIcon width={24} />,
    path: '/rentals',
    element: <DashboardRentals />,
  }]), []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Dashboard menus={menus} />}
        >
          {menus.map((menu) => (
            <Route
              key={menu.title}
              path={menu.path}
              element={menu.element}
            />
          ))}
          <Route
            path="/location/:id"
            element={<DashboardLocationDetails />}
          />
          <Route
            path="/location/new"
            element={<DashboardLocationNew />}
          />
          <Route
            path="/station/new"
            element={<DashboardStationNew />}
          />
          <Route path="/" element={<Navigate to="/stations" />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: 'green',
              color: 'white',
            },
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
            },
          },
        }}
      />
    </BrowserRouter>
  );
};

export default App;
