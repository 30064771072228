import { Fragment, useMemo } from 'react';
import TablePagination from './TablePagination';

interface Data {
  [key: string]: any;
  key: string | number;
}

interface Section<T extends Data> {
  key: string | number;
  name: string;
  data: T[];
  [key: string]: any;
}

interface Header {
  name: string;
  hidden?: boolean;
  right?: boolean;
  key: string | number;
}

interface TableContentProps<T extends Data> {
  header: Header[];
  data: T;
  index: number;
  onRowPress?: (data: T) => void;
}

interface TableProps<T extends Data> {
  data: Section<T>[] | T[];
  page?: number;
  totalPages?: number;
  header: Header[];
  onPageChange?: (page: number) => void
  onRowPress?: (data: T) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const TableRow = <T extends Data>({ header, data, index, onRowPress }: TableContentProps<T>) => (
  <tr
    key={data.key}
    className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t', onRowPress ? 'hover:cursor-pointer hover:bg-gray-50 transition-colors duration-200 ease-in-out' : '')}
    onClick={() => onRowPress?.(data)}
  >
    {header.map((item, index) => (
      ['string', 'number'].includes(typeof data[item.key]) ? (
        <td key={index} className={`whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 ${item.right ? 'text-right' : 'text-left'}`}>
          {data[item.key]}
        </td>
      ) : (
        <td key={index} className={`whitespace-nowrap px-3 py-2 ${item.right ? 'text-right' : 'text-left'}`}>
          <div className="inline-block">
            {data[item.key]}
          </div>
        </td>
      )
    ))}
  </tr>
);

const Table = <T extends Data>(props: TableProps<T>) => {
  const isSectioned = useMemo(() => Array.isArray(props.data[0]?.data), [props.data]);

  return (
    <>
      <div className="flow-root rounded-xl border border-gray-200 overflow-hidden">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <thead className="bg-orange-400">
                <tr>
                  {props.header.map((item, index) => (
                    item.hidden ? (
                      <th key={index} scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span className="sr-only">{item.name}</span>
                      </th>
                    ) : (
                      <th key={index} scope="col" className={`py-3.5 pl-4 pr-3 font-semibold text-white sm:pl-3 ${item.right ? 'text-right' : 'text-left'}`}>
                        {item.name}
                      </th>
                    )
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {isSectioned ? (props.data as Section<T>[]).map((section) => (
                  <Fragment key={section.key}>
                    <tr className="border-t border-gray-200">
                      <th
                        // colSpan={header.length}
                        scope="col"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        {section.name}
                      </th>
                      {props.header.slice(1).map((item, index) => (
                          section[item.key] ? (
                            ['string', 'number'].includes(typeof section[item.key]) ? (
                              <td key={index} className={`whitespace-nowrap bg-gray-50 py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 ${item.right ? 'text-right' : 'text-left'}`}>
                                {section[item.key]}
                              </td>
                            ) : (
                              <td key={index} className={`whitespace-nowrap bg-gray-50 px-3 py-1 ${item.right ? 'text-right' : 'text-left'}`}>
                                <div className="inline-block">
                                  {section[item.key]}
                                </div>
                              </td>
                            )
                          ) : (
                            <td key={index} className={`whitespace-nowrap bg-gray-50 py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 ${item.right ? 'text-right' : 'text-left'}`}>
                              {''}
                            </td>
                          )
                        ))}
                    </tr>
                    {section.data?.map((item, index) => (
                      <TableRow key={item.key} header={props.header} data={item} index={index} onRowPress={props.onRowPress} />
                    ))}
                  </Fragment>
                )) : (
                  (props.data as T[]).map((item, index) => (
                    <TableRow key={item.key} header={props.header} data={item} index={index} onRowPress={props.onRowPress} />
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {props.page != null && (
        <TablePagination
          page={props.page}
          totalPages={props.totalPages!}
          onPageChange={props.onPageChange!}
        />
      )}
    </>
  );
};

export default Table;
