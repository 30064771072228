import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import AssetsService from '../../../services/AssetsService';
import {
  Box,
  Equipment,
  EquipmentStateOfUse,
  EquipmentType,
  LockStatus,
  Station,
} from '../../../services/models/assets';
import { RentalStatus } from '../../../services/models/rental';
import ModalChooseEquipment from '../../../components/Modals/ModalChooseEquipment';
import ModalClosedMessage from '../../../components/Modals/ModalClosedMessgae';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from 'usehooks-ts';
import H2 from '../../../components/H2';
import Table from '../../../components/Table/Table';
import ModalEquipment from '../../../components/Modals/ModalEquipment';

const DashboardStations = () => {
  const [selectedStation, setSelectedStation] = useState<Station>();
  const [selectedBox, setSelectedBox] = useState<Box>();
  const [modalClosedMessageVisible, setModalClosedMessageVisible] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();

  const [locationId] = useLocalStorage<number | undefined>('locationId', undefined);

  const { data: stations, isFetching } = useQuery({
    queryKey: ['stations'],
    queryFn: () => AssetsService.getStations(locationId!),
    enabled: locationId != null,
    select: (data) => data.stations,
    refetchInterval: 5000,
  });

  const queryClient = useQueryClient();

  const toggleStationActivation = (station: Station) => {
    if (station.active) {
      setModalClosedMessageVisible(true);
      setSelectedStation(station);
    } else {
      AssetsService.activateStation(station.id)
        .then(() => {
          queryClient.resetQueries({ queryKey: ['stations'] });
        })
        .catch(console.warn);
    }
  };

  const tableHeaderStation = useMemo(() => {
    return [
      { name: 'Type', key: 'type' },
      { name: 'Modèle', key: 'model' },
      { name: 'État', key: 'stateOfUse' },
      { name: 'Prix', key: 'price' },
      { name: 'Caution', key: 'deposit' },
      { name: 'Réservation', right: true, key: 'rentalStatus' },
    ];
  }, []);

  useEffect(() => {
    if (stations != null && !isFetching && locationId != null) {
      queryClient.invalidateQueries({ queryKey: ['stations'] });
    }
  }, [locationId]);

  const renderRentalStatus = (status: RentalStatus) => {
    switch (status) {
      case RentalStatus.NOT_STARTED:
        return (
          <div className="h-8 px-2 flex items-center bg-green-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-green-400 rounded-full" />
              <span className="text-green-900">Disponible</span>
            </div>
          </div>
        );

      case RentalStatus.RENTED:
        return (
          <div className="h-8 px-2 flex items-center bg-gray-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-gray-400 rounded-full" />
              <span className="text-gray-900">Loué</span>
            </div>
          </div>
        );

      case RentalStatus.RETURNED:
        return (
          <div className="h-8 px-2 flex items-center bg-orange-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-orange-400 rounded-full" />
              <span className="text-orange-900">Retourné</span>
            </div>
          </div>
        );

      default:
        return (
          <div className="h-8 px-2 flex items-center bg-red-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-red-400 rounded-full" />
              <span className="text-red-900">Inconnu</span>
            </div>
          </div>
        );
    }
  };

  const renderStateOfUse = (state: EquipmentStateOfUse) => {
    switch (state) {
      case EquipmentStateOfUse.NEW:
        return 'Neuf';

      default:
        return 'Usagé';
    }
  };

  const renderType = (type: EquipmentType) => {
    switch (type) {
      case EquipmentType.SURF:
        return 'Surf';

      case EquipmentType.PADDLE:
        return 'Paddle';

      case EquipmentType.KAYAK:
        return 'Kayak';

      case EquipmentType.BIKE:
        return 'Vélo';

      default:
        return 'Inconnu';
    }
  };

  const renderLockStatus = (status: LockStatus) => {
    switch (status) {
      case LockStatus.OPENED:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-red-200 rounded-md">
            <span className="text-red-900">Ouvert</span>
          </div>
        );

      case LockStatus.CLOSED:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-green-200 rounded-md">
            <span className="text-green-900">Fermé</span>
          </div>
        );

      default:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-gray-200 rounded-md">
            <span className="text-gray-900">Inconnu</span>
          </div>
        );
    }
  };

  const toggleBoxActivation = (box: Box) => {
    (box.active
      ? AssetsService.deactivateBox(box.id)
      : AssetsService.activateBox(box.id))
      .then(() => {
        queryClient.resetQueries({ queryKey: ['stations'] });
      })
      .catch(console.warn);
  };

  const openBox = (box: Box) => {
    AssetsService.openBox(box.id)
      .then(() => {
        toast.success('Action envoyé');
      })
      .catch(console.warn);
  };

  const tableDataStation = useCallback((station: Station) => {
    return station.boxes.map((box) => ({
      key: box.id,
      name: `Box #${box.boxNumber}`,
      data: box.equipments.map((equipment) => ({
        type: renderType(equipment.type),
        model: (
          <div className="max-w-80">
            <p className="text-gray-900 text-wrap font-medium line-clamp-2">
              {equipment.model}
            </p>
            <p className="text-gray-700 text-wrap text-xs line-clamp-2">
              {equipment.description}
            </p>
          </div>
        ),
        price: (
          <div className="flex flex-col">
            {station.rentalDurations.map((duration) => {
              const equipmentPrice = equipment.equipmentPrices.find((price) => price.rentalDuration.duration === duration.duration && price.rentalDuration.unit === duration.unit);

              return (
                <div className="flex justify-between flex-1 gap-1">
                  <p className="text-xs text-gray-900">{`${duration.duration} ${duration.unit}:`}</p>
                  <p className={`text-xs ${equipmentPrice ? 'text-gray-900' : 'text-red-500'} font-bold text-right`}>{
                    equipmentPrice ? `${(Math.round(equipmentPrice.price) / 100)} €` : 'Prix non défini'
                  }</p>
                </div>
              );
            })}
          </div>
        ),
        deposit: `${equipment.deposit / 100} €`,
        stateOfUse: renderStateOfUse(equipment.stateOfUse),
        rentalStatus: renderRentalStatus(equipment.rentalStatus),
        key: equipment.id,
        equipment,
      })),
      rentalStatus: (
        <button
          type="button"
          className="underline font-semibold hover:underline disabled:hover:no-underline text-blue-400 disabled:text-gray-500"
          disabled={box.equipments.find((equipment) => equipment.rentalStatus === RentalStatus.RENTED) != null}
          onClick={() => setSelectedBox(box)}
        >
          <span>
            {box.equipments.length > 0 ? 'Changer l\'équipement' : 'Ajouter un équipement'}
          </span>
        </button>
      ),
      price: (
        <button
          type="button"
          className="font-semibold"
          onClick={() => openBox(box)}
        >
          <span className="underline text-blue-400 hover:underline">Ouvrir le box</span>
        </button>
      ),
      deposit: (
        <button
          type="button"
          className={`underline font-semibold ${box.active ? 'text-red-400' : 'text-green-400'} hover:underline disabled:hover:no-underline disabled:text-gray-500`}
          disabled={box.equipments.find((equipment) => equipment.rentalStatus === RentalStatus.RENTED) != null}
          onClick={() => toggleBoxActivation(box)}
        >
          <span>{box.active ? 'Désactiver le box' : 'Activer le box'}</span>
        </button>
      ),
      model: box.active
        ? (
          <div className="flex gap-2 items-center">
            {renderLockStatus(box.lockStatus)}
            <span className="text-green-400">Box Actif</span>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            {renderLockStatus(box.lockStatus)}
            <span className="text-red-400">Box Inactif</span>
          </div>
        ),
    }));
  }, [selectedStation]);

  const clearSelectedBox = () => {
    setSelectedBox(undefined);
  };

  const updateEquipmentInSelectedBox = (equipment?: Equipment) => {
    if (!selectedBox) {
      return;
    }
    AssetsService.updateBoxEquipment(selectedBox.id, equipment?.id)
      .then(() => {
        queryClient.resetQueries({ queryKey: ['stations'] });
      })
      .catch(console.warn);
  };

  const deactivateStation = (stationId: number, message?: string) => {
    AssetsService.deactivateStation(stationId, message)
      .then(() => {
        setModalClosedMessageVisible(false);
        queryClient.resetQueries({ queryKey: ['stations'] });
      })
      .catch(console.warn);
  };

  const closeAndRefresh = () => {
    setSelectedEquipment(undefined);
    queryClient.invalidateQueries({ queryKey: ['equipments'] });
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      {stations?.map((station) => (
        <div className="mb-5">
          <div className="flex gap-5 items-center justify-between">
            <div className="flex gap-2 items-center">
              {station.active ? (
                <div className="h-3 w-3 bg-green-400 rounded-full" />
              ) : (
                <div className="h-3 w-3 bg-red-600 rounded-full" />
              )}
              <H2>{`Station #${station.id}`}</H2>
              <p>{`(${station.voltage}V)`}</p>
            </div>
            <button
              type="button"
              className={`h-8 px-2 flex items-center ${station.active ? 'bg-red-600' : 'bg-green-400'} rounded-md`}
              onClick={() => toggleStationActivation(station)}
            >
              <span className="text-white">{station.active ? 'Désactiver la station' : 'Activer la station'}</span>
            </button>
          </div>
          <Table
            header={tableHeaderStation}
            data={tableDataStation(station)}
            onRowPress={(data) => setSelectedEquipment(data.equipment)}
          />
        </div>
      ))}
      <ModalChooseEquipment
        visible={selectedBox != null}
        selectedEquipmentId={selectedBox?.equipments[0]?.id}
        onClose={clearSelectedBox}
        onEquipmentSelect={updateEquipmentInSelectedBox}
        empty={(selectedBox?.equipments.length ?? 0) === 0}
      />
      <ModalClosedMessage
        visible={modalClosedMessageVisible}
        stationId={selectedStation?.id}
        onCancel={() => setModalClosedMessageVisible(false)}
        onConfirm={deactivateStation}
      />
      <ModalEquipment
        equipment={selectedEquipment}
        onClose={closeAndRefresh}
        visible={!!selectedEquipment}
      />
    </div>
  );
};

export default DashboardStations;
