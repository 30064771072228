import { Box } from './models/assets';
import { PaginationMetadata } from './models/pagination';
import { Rental } from './models/rental';
import Service from './Service';

class RentalService {
  private static URL_BASE = process.env.REACT_APP_URL_API;

  private static URL_RENTALS_CURRENT = (page: number) => `${this.URL_BASE}/rental/admin/current?page=${page}`;

  private static URL_RENTALS_HISTORY = (page: number) => `${this.URL_BASE}/rental/admin/history?page=${page}`;

  private static URL_RENTAL_END = () => `${this.URL_BASE}/rental/admin/end`;

  private static URL_RENTAL_CLOSE = () => `${this.URL_BASE}/rental/admin/close`;

  private static URL_RENTAL = (id: number) => `${this.URL_BASE}/rental/admin/${id}`;

  private static URL_RENTAL_AVAILABLE_BOXES = (rentalId: number) => `${this.URL_BASE}/rental/admin/${rentalId}/available-boxes`;

  static getRentalsCurrent(page: number): Promise<{ rentals: Rental[], metadata: PaginationMetadata }> {
    return Service.get(this.URL_RENTALS_CURRENT(page));
  }

  static getRentalsHistory(page: number): Promise<{ rentals: Rental[], metadata: PaginationMetadata }> {
    return Service.get(this.URL_RENTALS_HISTORY(page));
  }

  static endRental(id: number): Promise<{ success: boolean }> {
    return Service.post(this.URL_RENTAL_END(), {
      rentalId: id,
    });
  }

  static closeRental(id: number): Promise<{ success: boolean }> {
    return Service.post(this.URL_RENTAL_CLOSE(), {
      rentalId: id,
    });
  }

  static updateRentalStartDateUTC(id: number, startDateUTC: string): Promise<Rental> {
    return Service.patch(this.URL_RENTAL(id), { startDateUTC });
  }

  static changeRentalEquipment(id: number, previousEquipmentId: number, newEquipmentId: number): Promise<Rental> {
    return Service.patch(this.URL_RENTAL(id), { previousEquipmentId, newEquipmentId });
  }

  static getAvailableBoxes(rentalId: number): Promise<{ boxes: Box[] }> {
    return Service.get(this.URL_RENTAL_AVAILABLE_BOXES(rentalId));
  }
}

export default RentalService;
