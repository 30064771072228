import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  PowerIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Menu } from '../types/common';
import { useQuery } from '@tanstack/react-query';
import AssetsService from '../../../services/AssetsService';
import { useLocalStorage } from 'usehooks-ts';

interface SideMenuProps {
  menus: Menu[];
}

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>();
  const [showLocations, setShowLocations] = useState(false);

  const [locationId, setLocationId] = useLocalStorage<number | undefined>('locationId', undefined);

  const { data: locations } = useQuery({
    queryKey: ['locations'],
    queryFn: () => AssetsService.getLocations(),
    select: (data) => data.locations,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    if (locations && selectedLocationIndex == null) {
      if (locationId == null) {
        setLocationId(locations[0].id);
        setSelectedLocationIndex(locations[0].id);
      } else {
        const index = locations.findIndex((location) => location.id === locationId);

        if (index == -1) {
          setLocationId(locations[0].id);
          setSelectedLocationIndex(locations[0].id);
        } else {
          setLocationId(locations[index].id);
          setSelectedLocationIndex(index);
        }
      }
    }
  }, [locations]);

  const selectLocation = (locationId: number) => {
    setLocationId(locationId);
    setSelectedLocationIndex(Math.max(0, locations?.findIndex((location) => location.id === locationId) ?? 0));
    setShowLocations(false);
  };

  return (
    <div className="z-10 pointer-events-none pt-20 w-full xl:h-full xl:w-80 xl:pt-0">
      <div className={`w-80 h-full shadow-lg fixed bg-orange-400 ${visible ? 'z-100 flex flex-col' : 'hidden xl:flex flex-col'} right-0 xl:right-auto`}>
        <div className="flex-1">
          <ul className="pointer-events-auto p-5 pr-0 h-full">
            <div className="pb-5">
              <Link to="/" className="text-6xl font-semibold text-white italic bg-orange-400">
                Follow
              </Link>
            </div>
            <div className="mb-3">
              {locations != null && (
                <div
                  className={`select-none pl-3 py-2 flex gap-3 items-center border-b border-orange-400 rounded-s-xl ${showLocations ? 'bg-orange-100 text-orange-500' : `bg-orange-500 text-white ${locations.length > 1 ? 'hover:bg-orange-100 hover:text-orange-500' : ''}`} font-medium text-lg ${locations.length > 1 ? 'cursor-pointer' : ''}`}
                  onClick={locations.length > 1 ? () => setShowLocations((prev) => !prev) : undefined}
                >
                  {locations[selectedLocationIndex ?? 0].active ? (
                    <div className="h-3 w-3 bg-green-400 rounded-full" />
                  ) : (
                    <div className="h-3 w-3 bg-red-600 rounded-full" />
                  )}
                  <div className="flex flex-1 flex-col">
                    <p className="font-semibold">
                      {` ${locations[selectedLocationIndex ?? 0].name} `} 
                    </p>
                    <p className="text-sm">{`${locations[selectedLocationIndex ?? 0].addressStreet}${locations[selectedLocationIndex ?? 0].addressStreet_2 ? ` ${locations[selectedLocationIndex ?? 0].addressStreet_2}` : ''}`}</p>
                    <Link
                      to={`/location/${locations[selectedLocationIndex ?? 0].id}`}
                      className="font-normal text-sm underline text-blue-900 inline cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      (edit)
                    </Link>
                  </div>
                  {locations.length > 1 && (
                    <div className="flex flex-col items-center pr-2">
                      <ChevronUpIcon width={24} className="-mb-1" />
                      <ChevronDownIcon width={24} className="-mt-1" />
                    </div>
                  )}
                </div>
              )}
              {(locations?.length ?? 0) > 1 && showLocations && (
                <li className="ml-5 pl-5 bg-white rounded-bl-xl select-none">
                  <ul>
                    {locations?.map((location) => (
                      <li key={location.name}>
                        <div
                          className={'py-2 flex gap-3 items-center hover:bg-white text-orange-400 hover:text-orange-500 font-medium text-lg cursor-pointer select-none'}
                          onClick={() => selectLocation(location.id)}
                        >
                          <p className="flex-1">{location.name}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </div>
            {props.menus.map((menu, index) => (
              <li
                key={index}
                className="-ml-5 pl-5"
              >
                <Link
                  to={menu.path}
                  className={`pl-5 py-5 flex gap-3 items-center border-b border-orange-400 font-medium text-lg rounded-s-xl ${(pathname.includes(menu.path) || menu.includes?.reduce((bool, path) => bool || pathname.includes(path), false)) ? 'bg-white text-orange-500' : 'text-white hover:bg-white hover:text-orange-400'}`}
                  onClick={() => setVisible(false)}
                >
                  {menu.icon}{menu.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Link
          to="/station/new"
          className="pointer-events-auto ml-5 pl-5 py-5 flex gap-3 items-start border-b border-orange-400 font-medium text-lg rounded-s-xl text-white hover:bg-white hover:text-orange-400"
          onClick={() => setVisible(false)}
        >
          <PlusIcon width={24} />Ajouter une station
        </Link>
        <Link
          to="/logout"
          className="pointer-events-auto ml-5 mb-5 pl-5 py-5 flex gap-3 items-start border-b border-orange-400 font-medium text-lg rounded-s-xl text-white hover:bg-white hover:text-orange-400"
          onClick={() => setVisible(false)}
        >
          <PowerIcon width={24} />Se déconnecter
        </Link>
      </div>
      <div className="pointer-events-auto w-full h-20 shadow-lg fixed bg-orange-400 right-0 top-0 flex items-center px-5 justify-between xl:hidden">
        <p className="text-6xl font-semibold text-white italic bg-orange-400">Follow</p>
        <button
          type="button"
          className="p-2 flex justify-center items-center text-white"
          onClick={() => setVisible((prev) => !prev)}
        >
          {visible ? (
            <XMarkIcon height={40} />
          ) : (
            <Bars3Icon height={40} />
          )}
        </button>
      </div>
    </div>
  );
};

export default SideMenu;
