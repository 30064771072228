import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import AssetsService from '../../services/AssetsService';
import {
  Equipment,
  EquipmentPrice,
  EquipmentStateOfUse,
  EquipmentType,
} from '../../services/models/assets';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from 'usehooks-ts';
import { RentalDuration } from '../../services/models/rental';

interface ModalEquipmentProps {
  equipment?: Partial<Equipment>;
  visible: boolean;
  onClose: () => void;
}

const translatedUnit = (unit: string) => {
  switch (unit) {
    case 'minute':
      return 'minute';

    case 'hour':
      return 'heure';

    case 'day':
      return 'jour';

    case 'week':
      return 'semaine';

    case 'month':
      return 'mois';

    default:
      return unit;
  }
};

interface EquipmentPriceWithReadablePrice extends Omit<EquipmentPrice, 'id'> {
  readablePrice: string;
}

const ModalEquipment: React.FC<ModalEquipmentProps> = ({ equipment, visible, onClose }) => {
  const [updatedEquipment, setUpdatedEquipment] = useState<Partial<Equipment>>();
  const [newEquipmentPrices, setNewEquipmentPrices] = useState<EquipmentPriceWithReadablePrice[]>([]);
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deposit, setDeposit] = useState<string>('');

  const equipmentInput = useRef<HTMLInputElement>(null);

  const [locationId] = useLocalStorage<number | undefined>('locationId', undefined);

  const { data: rentalDurations } = useQuery({
    queryKey: ['rentalDurations'],
    queryFn: () => AssetsService.getRentalDurations(locationId!),
    select: (data) => data.rentalDurations,
    enabled: locationId != null,
  });

  const saveEnabled = useMemo(() => (
    JSON.stringify(equipment) !== JSON.stringify(updatedEquipment)
    && (updatedEquipment?.model?.length ?? 0) > 0
    && (updatedEquipment?.description?.length ?? 0) > 0
    && (updatedEquipment?.deposit ?? 0) > 0
    && (updatedEquipment?.imageUrl?.length ?? 0) > 0
    || (newEquipmentPrices.length > 0 && newEquipmentPrices.some((equipmentPrice) => equipmentPrice.price !== equipment?.equipmentPrices?.find((equipmentPrice) => equipmentPrice.rentalDuration.id === equipmentPrice.rentalDuration.id)?.price ?? 0))
  ), [equipment, updatedEquipment, newEquipmentPrices]);

  useEffect(() => {
    setUpdatedEquipment(equipment?.id ? equipment : {
      model: '',
      description: '',
      deposit: 0,
      imageUrl: '',
      stateOfUse: EquipmentStateOfUse.NEW,
      type: EquipmentType.BIKE,
    });
    setDeposit(`€ ${((equipment?.deposit ?? 0) / 100)
      .toString()
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
      .replace(/(\.\d\d).*/g, '$1')}`);
  }, [equipment]);

  useEffect(() => {
    if (equipment && rentalDurations) {
      setNewEquipmentPrices(rentalDurations.map((rentalDuration) => {
        const equipmentPrice = equipment?.equipmentPrices?.find((equipmentPrice) => equipmentPrice.rentalDuration.id === rentalDuration.id);

        return {
          ...(equipmentPrice ?? {}),
          price: equipmentPrice?.price ?? 0,
          readablePrice: `€ ${(equipmentPrice?.price ?? 0) / 100}`,
          rentalDuration,
        };
      }));
    }
  }, [rentalDurations, equipment]);

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];

    if (image) {
      if (image.size > 5 * 1024 * 1024) {
        toast.error('L\'image est trop lourde (5Mb maximum)');
      } else if (!image.type.startsWith('image/')) {
        toast.error('Le fichier n\'est pas une image');
      }
      setUploading(true);
      AssetsService.uploadImage(image)
        .then((response) => {
          setUpdatedEquipment((prev) => ({
            ...(prev ? prev : {}),
            imageUrl: response.url,
          }));
        })
        .catch(() => {
          toast.error('Erreur lors de l\'envoi de l\'image');
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const onValueChange = (key: keyof Equipment, value: string | number) => {
    setUpdatedEquipment((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onPriceChange = (rentalDuration: RentalDuration, value: string) => {
    const formattedPrice = `€ ${value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
      .replace(/(\.\d\d).*/g, '$1')}`;

    const equipmentPricesIndex = newEquipmentPrices.findIndex((e) => e.rentalDuration?.id === rentalDuration.id);

    setNewEquipmentPrices((prev) => [
      ...prev.slice(0, equipmentPricesIndex),
      {
        ...prev[equipmentPricesIndex],
        price: Math.round(parseFloat(formattedPrice.replace('€ ', '')) * 100),
        readablePrice: formattedPrice,
      },
      ...prev.slice(equipmentPricesIndex + 1),
    ]);
  };

  const onDepositChange = (value: string) => {
    let newValue = Math.round(parseFloat(value.replace('€ ', '')) * 100);

    if (isNaN(newValue)) {
      newValue = 0;
    }
    setUpdatedEquipment((prev) => {
      return {
        ...prev,
        deposit: newValue,
      };
    });
    setDeposit(`€ ${value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
      .replace(/(\.\d\d).*/g, '$1')}`);
  };

  const saveEquipment = () => {
    if (updatedEquipment && equipment?.id) {
      const changes = Object.keys(updatedEquipment).reduce((acc, key) => {
        if (!['updatedAt', 'createdAt'].includes(key)
          && (JSON.stringify(equipment[key as keyof Equipment]) !== JSON.stringify(updatedEquipment[key as keyof Equipment]))) {
          acc[key as 'model'] = updatedEquipment[key as keyof Equipment] as string;
        }
        return acc;
      }, {} as Partial<Equipment>);
      AssetsService.updateEquipment(
        equipment.id,
        changes,
        newEquipmentPrices,
      )
        .then(() => {
          onClose();
          toast.success('Équipement mis à jour.');
        })
        .catch((error) => {
          console.warn(error);
          if (error.message) {
            toast.error(error.message);
          }
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      setSaving(false);
    }
  };

  const createEquipment = () => {
    if (updatedEquipment) {
      AssetsService.createEquipment(
        updatedEquipment,
        newEquipmentPrices,
      )
        .then(() => {
          onClose();
          toast.success('Équipement créé.');
        })
        .catch((error) => {
          console.warn(error);
          if (error.message) {
            toast.error(error.message);
          }
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      setSaving(false);
    }
  };

  const onSave = () => {
    setSaving(true);
    if (equipment?.id) {
      saveEquipment();
    } else {
      createEquipment();
    }
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Dialog as="div" className="relative z-10" onClose={(!saving && !uploading) ? onClose : () => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true"
                  />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mt-2">
                      <form>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6">
                            <label
                              htmlFor="image"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Image
                            </label>
                            {uploading ? (
                              <div className="flex w-full items-center justify-center h-60 bg-gray-200 rounded-lg">
                                <p className="text-gray-500">Envoi de l'image...</p>
                              </div>
                            ) : (
                              <button
                                className="flex w-full items-center gap-3 justify-center hover:opacity-90"
                                onClick={(e) => {
                                  e.preventDefault();
                                  equipmentInput.current?.click();
                                }}
                              >
                                <input
                                  type="file"
                                  id="equipment"
                                  name="image"
                                  ref={equipmentInput}
                                  className="hidden bg-gray-200"
                                  accept="image/*"
                                  onChange={onImageChange}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                                {updatedEquipment?.imageUrl ? (
                                  <div>
                                    <img
                                      src={updatedEquipment?.imageUrl}
                                      alt="Banner"
                                      className="w-full h-60 bg-gray-200 rounded-lg object-cover"
                                    />
                                    <p className="text-gray-900 font-bold hover:underline">Changer l'image</p>
                                  </div>
                                ) : (
                                  <>
                                    <div className="w-full h-60 bg-gray-200 rounded-lg" />
                                    <div className="absolute">
                                      <p className="text-gray-900 text-xl font-bold">Choisir une image</p>
                                      <p className="text-gray-700 font-light">jpg, jpeg, png - 5Mb maximum</p>
                                    </div>
                                  </>
                                )}
                              </button>
                            )}
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="model"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Modèle
                            </label>
                            <input
                              type="text"
                              name="model"
                              id="model"
                              defaultValue={updatedEquipment?.model ?? ''}
                              onChange={(e) => onValueChange('model', e.target.value)}
                              className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="col-span-6">
                            <div className="flex justify-between">
                              <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="description">
                                Description
                              </label>
                              <span className="text-gray-500">{`${updatedEquipment?.description?.length ?? 0}/2000`}</span>
                            </div>
                            <textarea
                              className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline min-h-24"
                              id="description"
                              rows={4}
                              maxLength={2000}
                              defaultValue={updatedEquipment?.description ?? ''}
                              onChange={(e) => onValueChange('description', e.target.value)}
                            />
                          </div>
                          <div className="col-span-6 grid grid-cols-2 gap-4">
                            {rentalDurations?.reduce((rows: React.ReactNode[][], rentalDuration, index) => {
                              if (index % 2 === 0) {
                                rows.push([]);
                              }
                              rows[rows.length - 1]!.push(
                                <div key={index} className="col-span-2 sm:col-span-1">
                                  <label
                                    htmlFor={`price-${rentalDuration.duration}-${rentalDuration.unit}`}
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                  >
                                    Prix / {rentalDuration.duration > 1 ? `${rentalDuration.duration} ` : ''}{translatedUnit(rentalDuration.unit)}
                                  </label>
                                  <input
                                    type="text"
                                    name={`price-${rentalDuration.duration}-${rentalDuration.unit}`}
                                    id={`price-${rentalDuration.duration}-${rentalDuration.unit}`}
                                    value={(newEquipmentPrices.find((equipmentPrice) => equipmentPrice.rentalDuration.id === rentalDuration.id)?.readablePrice ?? '€ 0')}
                                    onChange={(e) => onPriceChange(rentalDuration, e.target.value)}
                                    className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                  />
                                </div>
                              );
                              return rows;
                            }, [])}
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="deposit"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Caution
                            </label>
                            <input
                              type="text"
                              name="deposit"
                              id="deposit"
                              value={deposit}
                              className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => onDepositChange(e.target.value)}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="stateOfUse"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              État d'utilisation
                            </label>
                            <select
                              id="stateOfUse"
                              name="stateOfUse"
                              className="shadow border border-gray-300 rounded w-full py-2 px-2 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                              value={updatedEquipment?.stateOfUse ?? EquipmentStateOfUse.NEW}
                              onChange={(e) => onValueChange('stateOfUse', e.target.value)}
                            >
                              <option value={EquipmentStateOfUse.NEW}>Neuf</option>
                              <option value={EquipmentStateOfUse.USED}>Usagé</option>
                            </select>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="type"
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Type
                            </label>
                            <select
                              id="type"
                              name="type"
                              className="shadow border border-gray-300 rounded w-full py-2 px-2 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                              value={updatedEquipment?.type ?? EquipmentType.BIKE}
                              onChange={(e) => onValueChange('type', e.target.value)}
                            >
                              <option value={EquipmentType.SURF}>Surf</option>
                              <option value={EquipmentType.PADDLE}>Paddle</option>
                              <option value={EquipmentType.KAYAK}>Kayak</option>
                              <option value={EquipmentType.BIKE}>Vélo</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 mr-5 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:opacity-50 disabled:cursor-default disabled:hover:bg-white"
                    onClick={onSave}
                    disabled={!saveEnabled || saving || uploading}
                  >
                    Sauvegarder
                  </button>
                  <button
                    type="button"
                    className="mt-3 mr-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                    disabled={saving || uploading}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalEquipment;
