import { Box, Equipment, EquipmentPrice, Location, Station } from './models/assets';
import { RentalDuration } from './models/rental';
import Service from './Service';

class AssetsService {
  private static URL_BASE = process.env.REACT_APP_URL_API;

  private static URL_LOCATIONS = () => `${this.URL_BASE}/assets/admin/locations`;

  private static URL_STATIONS = (locationId: number) => `${this.URL_BASE}/assets/admin/location/${locationId}/stations`;

  private static URL_RENTAL_DURATIONS = (locationId: number) => `${this.URL_BASE}/assets/admin/location/${locationId}/rental-durations`;

  private static URL_LOCATION_CREATE = () => `${this.URL_BASE}/assets/admin/location/create`;

  private static URL_LOCATION = (value: number | string) => `${this.URL_BASE}/assets/admin/location/${value}`;

  private static URL_EQUIPMENTS = () => `${this.URL_BASE}/assets/admin/equipments`;

  private static URL_EQUIPMENT = (id?: number) => `${this.URL_BASE}/assets/admin/equipment?id=${id}`;

  private static URL_EQUIPMENT_CREATE = () => `${this.URL_BASE}/assets/admin/equipment/create`;

  private static URL_STATION_CREATE = () => `${this.URL_BASE}/assets/admin/station/link`;

  private static URL_BOX = () => `${this.URL_BASE}/assets/admin/box`;

  private static URL_UPLOADIMAGE = () => `${this.URL_BASE}/upload-image`;

  private static URL_STATION = (action: 'activate' | 'deactivate') => `${this.URL_BASE}/assets/admin/station/${action}`;

  static getLocations(): Promise<{ locations: Location[] }> {
    return Service.get(this.URL_LOCATIONS());
  }

  static getRentalDurations(locationId: number): Promise<{ rentalDurations: RentalDuration[] }> {
    return Service.get(this.URL_RENTAL_DURATIONS(locationId));
  }

  static getStations(locationId: number): Promise<{ stations: Station[] }> {
    return Service.get(this.URL_STATIONS(locationId));
  }

  static getLocation(id: number): Promise<{ location: Location }> {
    return Service.get(this.URL_LOCATION(id));
  }

  static updateLocation(id: number, location: Partial<Location>): Promise<{ location: Location }> {
    return Service.patch(this.URL_LOCATION(id), location);
  }

  static createLocation(location: Partial<Location>): Promise<{ location: Location }> {
    return Service.post(this.URL_LOCATION_CREATE(), {
      name: location.name,
      description: location.description,
      addressStreet: location.addressStreet,
      addressStreet_2: location.addressStreet_2,
      addressCity: location.addressCity,
      addressPostalCode: location.addressPostalCode,
      addressCountry: 'France', // todo: add country
      websiteUrl: location.websiteUrl,
      termsUrl: location.termsUrl,
      schedules: location.schedules,
    });
  }

  static linkStation(station: Partial<Station>): Promise<{ station: Station }> {
    return Service.post(this.URL_STATION_CREATE(), {
      token: station.token,
      lat: station.lat,
      lng: station.lng,
      locationId: station.locationId,
    });
  }

  static activateBox(boxId: number): Promise<{ box: Box }> {
    return Service.post(this.URL_BOX(), {
      boxId,
      action: 'activate',
    });
  }

  static deactivateBox(boxId: number): Promise<{ box: Box }> {
    return Service.post(this.URL_BOX(), {
      boxId,
      action: 'deactivate',
    });
  }

  static openBox(boxId: number): Promise<{ box: Box }> {
    return Service.post(this.URL_BOX(), {
      boxId,
      action: 'open',
    });
  }

  static uploadImage(image: File): Promise<{ url: string }> {
    const formData = new FormData();

    formData.append('image', image);
    return Service.post(this.URL_UPLOADIMAGE(), formData);
  }

  static getEquipments(): Promise<{ equipments: Equipment[] }> {
    return Service.get(this.URL_EQUIPMENTS());
  }

  static updateEquipment(
    equipmentId: number,
    equipment: Partial<Equipment>,
    equipmentPrices: { id?: number; price: number }[],
  ): Promise<{ success: boolean }> {
    return Service.patch(this.URL_EQUIPMENT(equipmentId), {
      equipment,
      equipmentPrices,
    });
  }

  static createEquipment(equipment: Partial<Equipment>, equipmentPrices: { price: number; rentalDuration: { id: number } }[]): Promise<{ success: boolean }> {
    return Service.post(this.URL_EQUIPMENT_CREATE(), {
      equipment,
      equipmentPrices,
    });
  }

  static updateBoxEquipment(boxId: number, equipmentId?: number): Promise<{ success: boolean }> {
    return Service.patch(this.URL_BOX(), {
      boxId,
      equipmentId,
    });
  }

  static activateStation(stationId: number): Promise<{ station: Station }> {
    return Service.post(this.URL_STATION('activate'), {
      stationId,
    });
  }

  static deactivateStation(stationId: number, message?: string): Promise<{ station: Station }> {
    return Service.post(this.URL_STATION('deactivate'), {
      stationId,
      message,
    });
  }

  static activateLocation(locationId: number): Promise<{ station: Station }> {
    return Service.post(this.URL_LOCATION('activate'), {
      locationId,
    });
  }

  static deactivateLocation(locationId: number): Promise<{ station: Station }> {
    return Service.post(this.URL_LOCATION('deactivate'), {
      locationId,
    });
  }
}

export default AssetsService;
