import { PlusIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import H1 from '../../../../components/H1';
import ModalEquipment from '../../../../components/Modals/ModalEquipment';
import Table from '../../../../components/Table/Table';
import AssetsService from '../../../../services/AssetsService';
import { Equipment } from '../../../../services/models/assets';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const DashboardEquipments = () => {
  const [selectedEquipment, setSelectedEquipment] = useState<Partial<Equipment>>();

  const { data: equipments } = useQuery({
    queryKey: ['equipments'],
    queryFn: () => AssetsService.getEquipments(),
    select: (data) => data.equipments,
  });

  const queryClient = useQueryClient();

  const tableHeader = useMemo(() => ([
    { name: 'Image', key: 'image', hidden: true },
    { name: 'Modèle', key: 'model' },
    { name: 'Status', hidden: true, right: true, key: 'status' },
    { name: 'Station', key: 'station' },
    { name: 'Box', key: 'box' },
  ]), []);

  const tableData = useMemo(() => (
    equipments?.map((equipment) => ({
      image: (
        <img src={equipment.imageUrl} alt={equipment.model} className="w-10 h-10 rounded-lg" />
      ),
      model: (
        <div className="max-w-80">
          <p className="text-gray-900 text-wrap font-medium line-clamp-2">
            {equipment.model}
          </p>
          <p className="text-gray-700 text-wrap text-xs line-clamp-2">
            {equipment.description}
          </p>
        </div>
      ),
      station: equipment.box?.stationId,
      box: equipment.box?.boxNumber,
      id: equipment.id,
      key: equipment.id,
    })) ?? []
  ), [equipments]);

  const onEquipmentPress = (data: (typeof tableData)[number]) => {
    setSelectedEquipment(equipments?.find((equipment) => equipment.id === data.id));
  };

  const closeAndRefresh = () => {
    setSelectedEquipment(undefined);
    queryClient.invalidateQueries({ queryKey: ['equipments'] });
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>Équipements</H1>
      <div className="flex justify-end mb-5">
        <button
          type="button"
          className="px-4 py-2 bg-orange-400 rounded-full text-white font-semibold shadow disabled:shadow-none disabled:bg-gray-300"
          onClick={() => setSelectedEquipment({})}
        >
          <PlusIcon width={20} height={20} className="inline-block" />
          <span className="ml-2">Ajouter un équipement</span>
          
        </button>
      </div>
      <Table
        header={tableHeader}
        data={tableData}
        onRowPress={onEquipmentPress}
      />
      <ModalEquipment
        equipment={selectedEquipment}
        onClose={closeAndRefresh}
        visible={!!selectedEquipment}
      />
    </div>
  );
};

export default DashboardEquipments;
